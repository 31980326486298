<template>
  <section class="order-query">
    <div class="d1">
      <div class="flex alignFlexEnd justifySpaceBetween">
        <div class="flex alignFlexEnd">
          <div>
            <label>查询条件</label>
            <el-select
              v-model="condition"
              placeholder="请选择"
              @change="changeCondition"
            >
              <el-option
                v-for="item in voucherConditionList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="mlr">
            <el-input
              :disabled="canBeInput"
              v-model.trim="formSearch[condition]"
              placeholder="选择查询条件后输入"
            ></el-input>
          </div>
          <div>
            <label>兑换券状态</label>
            <el-select v-model="formSearch.status" placeholder="请选择状态">
              <el-option
                v-for="item in exchangeConditionList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div>
          <el-button type="primary" @click="onSubmit">查询</el-button>
          <el-button @click="onReset">重置</el-button>
        </div>
      </div>

      <div class="add" @click="addVoucher">
        <i class="el-icon-circle-plus-outline"></i>
        <span>新增</span>
      </div>
    </div>

    <Table
      :baseData="baseData"
      :tableHeader="tableHeader"
      @handleCurrent="handleCurrentChange"
      :isShowPagination="true"
      :total="total"
      :pageSize="formSearch.pageSize"
      :currentPage="formSearch.pageNo"
    >
      <el-table-column label="操作" width="80" fixed="right">
        <template slot-scope="scope">
          <div class="opration check">
            <span v-if="scope.row.status == 0" @click="editVoucher(scope.row)">编辑</span>
          </div>
        </template>
      </el-table-column>
    </Table>

  </section>
</template>
<script>
import { voucherConditionList, exchangeConditionList } from "@/util/constant";
import API from "@/api/api_tool.js";
import { mapMutations } from 'vuex';

export default {
  name: "OrderQuery",
  components: {
  },
  data() {
    return {
      voucherConditionList,
      exchangeConditionList,
      condition: undefined,
      formSearch: {
        pageNo: 1,
        pageSize: 10,
        voucherCode: undefined,
        status: undefined,
      },
      baseData: [],
      tableHeader: this.tableHeader.voucher.tableHeader,
      total: 0,
    };
  },
  computed: {
    canBeInput: function () {
      return this.condition === undefined;
    },
  },
  methods: {
    ...mapMutations(["setVoucherDesc"]),
    onSubmit() {
      this.formSearch.pageNo = 1;
      this.getDataList();
    },
    changeCondition() {
      this.formSearch.voucherCode = undefined;
    },
    onReset() {
      this.condition = undefined;
      this.changeCondition();
      this.formSearch.status = undefined;
    },
    handleCurrentChange(pageNo) {
      this.formSearch.pageNo = pageNo;
      this.getDataList();
    },
    getDataList() {
      API.voucherRecords(this.formSearch).then((res = {}) => {
        const {
          data: { data: { records = [], total } = {}, code, msg = "" } = {}
        } = res;
        if(code == 200 && records && records.length > 0) {
          this.baseData = records;
          this.total = total;
        }else {
          this.baseData = [];
        }
      });
    },
    addVoucher() {
      this.$router.push({
        name: 'AddVoucher',
      });
    },
    editVoucher(res) {
      this.setVoucherDesc(res);
      this.$router.push({
        name: 'AddVoucher',
        query: {
          id: res.id,
        }
      });
    }
  },
  activated() {
    this.onReset();
    this.getDataList();
  }
};
</script>
<style lang="scss" scoped>
label {
  display: block;
  margin-bottom: 8px;
}
.d1 {
  padding: 32px;
}
.mlr {
  margin-right: 56px;
  margin-left: 12px;
}
.add {
  padding: 10px 16px;
  margin-top: 32px;
  gap: 8px;
  width: 84px;
  height: 40px;
  background: #ff9428;
  border-radius: 4px;
  font-family: "Microsoft YaHei UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
}
</style>